import React, { useContext, useEffect, useRef, useState } from "react";
import { SocketContext } from "../../../../Context/index";
import { useSelector } from "react-redux";
import {
  fetchChat,
  getFbMessages,
  send_sms,
  sendFbMessage,
  sendInstagramSms,
} from "../../../../Services/api";
import Message from "../../Static/Message";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import axios from "axios";
import { backEndUrl } from "../../../../Network/path";
import ImagePreview from "../../../../Components/ImagePreview";
import Calling from "../../../../Components/Calling/Player";

const ChatSection = ({
  // currentUser,
  page,
  setPage,
  searching,
  setSearching,
  msgId,
}) => {
  const {
    offer,
    setOffer,
    currentUser,
    socket,
    setImages,
    setCurrentChat,
    setFile,
    updateObjectInArray,
    chatUser,
    setChatUser,
    access,
  } = useContext(SocketContext);
  const selector = useSelector((state) => state.auth);
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sms, setSms] = useState("");
  const [files, setFiles] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [offerMessage, setOfferMessages] = useState("");
  const [box, setSelectionBox] = useState(false);

  const messageRef = useRef(null);

  console.log("----selector.auth---->>>.", selector.auth);

  const getInitialChat = async (page) => {
    try {
      setIsLoading(true);
      const response = await getChatFunn(page);
      console.log("response------------>", response);
      setMessages(response);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getChatFunn = async (page) => {
    try {
      const body = {
        page: page,
        selectionId: currentUser?.chat_user_id,
        id: currentUser?.connection_id,
      };
      const response = await fetchChat(body);
      return response.data.data;
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessageHandler = () => {
    if (currentUser.status === "block") {
      toast.error("This user is blocked");
    } else {
      currentUser.type === "facebook"
        ? fbMessageHandler()
        : currentUser.type === "instagram"
        ? sendInstaMessage()
        : currentUser.type === "whatsApp"
        ? sendWhatsAppMessage()
        : sendMsg();
    }
  };

  const sendWhatsAppMessage = async() =>{
    try {
      let date = Math.round(new Date().getTime() / 1000);
      let body={temp:"",id:currentUser.phone_number_id,token:currentUser.access_token,sms:sms,type:"message",con_id:currentUser.connection_id,phone:currentUser.name};
      console.log("body--------->",body);
      const res = await send_sms(body);
      if (res.status) {
        const new_sms = {
          message: sms,
          receiver_id: currentUser.chat_user_id,
          sender_id: currentUser.connection_id,
          type: "text",
          sms_status: "read",
          call_id: "",
          mail: "",
          unix_time: date,
        };
        setMessages([...messages, new_sms]);
        setSms("");
      }
    } catch (error) {}
  }

  const sendMsg = () => {
    if (sms.trim() !== "" || files) {
      var date = Math.round(new Date().getTime() / 1000);
      let dts = {
        sender_id: currentUser?.connection_id,
        receiver_id: currentUser?.chat_user_id,
        type:
          sms !== "" && files !== ""
            ? "text_with_file"
            : files !== ""
            ? "file"
            : offer !== ""
            ? "application"
            : "text",
        file_type:
          selectedFile && selectedFile !== null
            ? selectedFile.type.split("/")[0]
            : "",
        file: files,
        sms_status: "sent",
        sender_type: "member",
        message: sms,
        unix_time: date,
      };
      setOfferMessages("");
      sendMessage(dts);
      setFiles("");
      setSelectedFile(null);
      setSms("");
    }
  };

  const sendMessage = (data) => {
    socket.emit("stop_typing_widget", { user: currentUser?.chat_user_id });
    socket.emit("send_sms_widget", data);
    if (data.file_type === "image") {
      setImages((prev) => [...prev, data]);
    }
    if (data.file_type === "application") {
      setFile((prev) => [...prev, data]);
    }
    setCurrentChat((prevData) => [...prevData, data]);
    setMessages((prevData) => [...prevData, data]);
  };

  const fbMessageHandler = async () => {
    try {
      let date = Math.round(new Date().getTime() / 1000);
      const body = {
        recipient_id: currentUser.user_socialId,
        message: sms,
        pageId: currentUser.connected_id,
        pageToken: currentUser.access_token,
        connection_id: currentUser.id,
        sender_id: currentUser.connection_id,
        receiver_id: currentUser.chat_user_id,
      };
      const res = await sendFbMessage(body);
      if (res.data.status) {
        const new_sms = {
          message: sms,
          receiver_id: currentUser.chat_user_id,
          sender_id: currentUser.connection_id,
          type: "text",
          sms_status: "read",
          call_id: "",
          mail: "",
          unix_time: date,
        };
        setMessages([...messages, new_sms]);
        setSms("");
      }
    } catch (error) {}
  };

  const handleChange = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setSelectionBox(false);
    setSelectedFile(file);
    const token = localStorage.getItem("tokenk");
    const headers = {
      "x-access-token": token,
    };
    let formData = new FormData();
    formData.append("file", file);
    axios
      .post(backEndUrl + "apis/v3/uploadChat", formData, {
        headers: headers,
      })
      .then((response) => {
        if (response && response.data && response.data.file) {
          setFiles(response.data.file);
        }
      })
      .catch((error) => {
        console.log("error zip", error);
      });
  };

  const sendInstaMessage = async () => {
    try {
      let date = Math.round(new Date().getTime() / 1000);
      const body = {
        recipientId: currentUser.user_socialId,
        sms: sms,
        pageToken: currentUser.access_token,
        connection_id: currentUser.id,
        receiver_id: currentUser.chat_user_id,
        sender_id: currentUser.connection_id,
      };
      const resp = await sendInstagramSms(body);
      const new_sms = {
        message: sms,
        receiver_id: currentUser.chat_user_id,
        sender_id: currentUser.connection_id,
        type: "text",
        sms_status: "read",
        call_id: "",
        mail: "",
        unix_time: date,
      };
      setMessages([...messages, new_sms]);
      setSms("");
    } catch (error) {
      console.log("error zip", error);
    }
  };

  useEffect(() => {
    getInitialChat(page);
    if (socket) {
      socket.on(
        `send_sms_widget_${selector.userDetails?.key}_${selector.userDetails.chat_id}_${currentUser.chat_user_id}`,
        (data) => {
          socket.emit("update_chat_status_widget", {
            user: currentUser?.chat_user_id,
            status: "read",
          });
          setMessages((prevData) => [...prevData, data]);
          if (data.file_type === "image") {
            setImages((prev) => [...prev, data]);
          }
          if (data.file_type === "application") {
            setFile((prev) => [...prev, data]);
          }
        }
      );
    }
    return () => {
      if (socket) {
        socket.off(
          `send_sms_widget_${selector.auth?.key}_${selector.auth.chat_id}_${currentUser?.chat_user_id}`
        );
      }
    };
  }, [currentUser]);

  console.log("currentUser.connection_id",currentUser.connection_id);

  useEffect(() => {
    if (messageRef.current && page == 1) {
      messageRef.current.scrollTop = messageRef.current.scrollHeight;
    }
  }, [messages]);

  console.log(messages, "MESSAGES");
  console.log(currentUser, "CURRENTUSER");
  return (
    <div className="col mid-section p-0 d-flex flex-column">
      {/* Chat Header */}
      {currentUser && Object.keys(currentUser).length > 0 && (
        <div className="chat-header p-3 bg-white d-flex justify-content-between align-items-center w-100">
          <div className="d-flex justify-content-between align-items-center w-100">
            <h6 className="m-0">
              {currentUser.image ? (
                <img
                  src="../assets/images/social-user.png"
                  alt="Jhon Bieber"
                  className="rounded-circle me-3 "
                  style={{ width: "50px" }}
                />
              ) : (
                <span className="dyna-user me-2">{currentUser.name[0]}</span>
              )}
              {currentUser.name}
            </h6>
            {/* <div className="calling d-flex">
            <div className="icons">
            <i class="bi bi-trash3-fill" style={{ color: "#FF543E" }}></i>
            <i class="bi bi-trash3-fill" style={{ color: "#FF543E" }}></i>
            </div>
            <div className="timmer">
              <span>01:09 sec</span>
            </div>
          </div> */}
            {access && <Calling />}
            <div className="chat-actions">
              <button className="btn">
                <i
                  className="bi bi-trash3-fill"
                  style={{ color: "#FF543E" }}
                ></i>
                <i
                  className="fa fa-ban"
                  aria-hidden="true"
                  style={{ color: "#FF543E" }}
                ></i>
                <i className="bi bi-pin-angle-fill me-0"></i>
              </button>
            </div>
          </div>
        </div>
      )}

      {isLoading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : (
        <>
          <div
            className="chat-body p-3 flex-grow-1 overflow-auto"
            ref={messageRef}
          >
            {messages &&
              messages.length > 0 &&
              messages.map((message) => (
                <Message
                  me={
                    currentUser.type && currentUser.type === "widget"
                      ? selector.auth.chat_id == message.sender_id
                      : currentUser.connection_id == message.sender_id
                  }
                  message={message.message}
                  time={message.unix_time}
                  file={message.file}
                  fileType={message.file_type}
                />
              ))}
          </div>
          <div className="chat-input p-3">
            <div className="input-group1">
              <input
                type="text"
                className="form-control"
                placeholder="U Say What's Going On?"
                value={sms}
                onChange={(e) => setSms(e.target.value)}
              />
              <input type="file" onChange={handleChange} />
              <button className="btn" onClick={sendMessageHandler}>
                <i className="bi bi-send-fill"></i>
              </button>
            </div>
          </div>
          <ImagePreview
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
          />
        </>
      )}
    </div>
  );
};

export default ChatSection;
