import React, { useEffect, useState, useContext } from "react";
import SideBar from "./Functional/sidebar";
import ChatList from "./Functional/Chatlist";
import ChatSection from "./Functional/ChatSection";
import ProfileSection from "./Functional/ProfileSection";
import "./chat.css";
import Notifications from "../../Components/Calling/Notifications";
import Calling from "../../Components/Calling/Player";
import { SocketContext } from "../../Context";
import ChatWidgetForm from "../ConnectionStepForm/ChatWidget";

import { useSelector } from "react-redux";
import { getDetailsWithKeyApi, getSocialConnection } from "../../Services/api";
import WhatsApp from "../ConnectionStepForm/whatsApp";
import FacebookConnection from "../ConnectionStepForm/facebook";
import InstaConnection from "../ConnectionStepForm/instagram";

const NewChat = () => {
  const [currentTab, setCurrentTab] = useState("home");
  const [page, setPage] = useState(1);
  const { userDetails } = useSelector((state) => state.auth);
  const { access } = useContext(SocketContext);

  const [connections, setConnections] = useState([]);
  const [searching, setSearching] = useState("");

  const getConnections = async () => {
    try {
      const res = await getSocialConnection();
      const conn = res.data.data;
      conn.forEach((el) => {
        setConnections((prev) => [...prev, el.type]);
      });
    } catch (error) {
      console.log("Error", error.message);
    }
  };

  return (
    <div className="container-fluid">
      <div className="row h-100" style={{ borderTop: "1px solid #EFEFEF" }}>
        {/* <!-- Sidebar --> */}
        <SideBar
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          connections={connections}
        />

        {/* <!-- Chat Section --> */}
        <div className="col">
          <div className="tab-content" id="myTabContent">
            <div
              className={`tab-pane fade ${
                currentTab === "home" && "show active"
              }`}
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="row h-100">
                {/* ChatList */}
                <ChatList page={page} setPage={setPage} />
                {/* <!-- Chat Window --> */}
                <ChatSection
                  page={page}
                  setPage={setPage}
                  searching={searching}
                  setSearching={setSearching}
                />
                {/* <!-- User Info Section --> */}
                <ProfileSection />
              </div>
            </div>
            <div
              className={`tab-pane fade ${
                currentTab === "facebook" && "show active"
              }`}
              id="profile"
              role="tabpanel"
              aria-labelledby="profile-tab"
            >
              <FacebookConnection setCurrentTab={setCurrentTab}/>
            </div>
            <div
              className={`tab-pane fade ${
                currentTab === "instagram" && "show active"
              }`}
              id="contact"
              role="tabpanel"
              aria-labelledby="contact-tab"
            >
              <InstaConnection setCurrentTab={setCurrentTab} />
            </div>
            <div
              className={`tab-pane fade ${
                currentTab === "whatsapp" && "show active"
              }`}
              id="store"
              role="tabpanel"
              aria-labelledby="store-tab"
            >
              <WhatsApp />
            </div>
            <div
              className={`tab-pane fade ${
                currentTab === "widget" && "show active"
              }`}
              id="wtsapp"
              role="tabpanel"
              aria-labelledby="wtsapp-tab"
            >
              <div className="row h-100">
                <ChatWidgetForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Notifications />
    </div>
  );
};

export default NewChat;
