import Modal from "react-bootstrap/Modal";
function Example({
  show,
  onHide,
  component,
  style,
  size,
  backDrop,
  hideCloseBtn,
}) {
  return (
    <>
      <Modal
        size={size}
        show={show}
        onHide={onHide}
        className={style}
        dialogClassName="modal-90w"
        backdrop={backDrop}
        centered
        keyboard={false}
      >
        {!hideCloseBtn && (
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => onHide()}
          ></button>
        )}
        {component}
      </Modal>
    </>
  );
}

export default Example;
