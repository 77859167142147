import React from "react";

const socialTypes = [
  {
    id: 1,
    name: "Chat/Call Widget",
    description:
      "Talk to your website visitors, send friendly invites, and set up smart chatbot interactions.",
    image: "../assets/images/social-msg.png",
    type: "widget",
  },
  {
    id: 2,
    name: "Facebook Messenger",
    description:
      "Connect instantly, send custom invites, and automate conversations.",
    image: "../assets/images/social-fb.png",
    type: "facebook",
  },
  {
    id: 3,
    name: "Instagram Direct",
    description:
      "Engage smoothly, handle messages easily, and enhance with smart automation.",
    image: "../assets/images/social-insta.png",
    type: "instagram",
  },
  {
    id: 4,
    name: "Whatsapp Business API",
    description:
      "Chat in real time, reply quickly, and streamline with automation.",
    image: "../assets/images/social-wtsapp.png",
    type: "whatsapp",
  },
];

const StepOne = ({ nextStep, setType }) => {
  return (
    <div className="col-lg-8">
      <div className="main-right">
        <div className="row justify-content-center align-items-center">
          {socialTypes &&
            socialTypes.map((social) => (
              <div className="col-md-6" key={social.id}>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div
                    className="img-border"
                    onClick={() => {
                      setType(social.type);
                      nextStep();
                    }}
                  >
                    <img src={social.image} className="img-fluid" />
                  </div>
                  <h2>{social.name}</h2>
                  <p className="text-center">{social.description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default StepOne;
