import axios from "axios";
import React, { useEffect, useState } from "react";
// import GoogleLogin from "./googleLogin";
import { addSocialApi, existingConnectionApi, linkInstagram, linkSocialApi, wpConnect } from "../../Services/api";
import { useDispatch, useSelector } from "react-redux";
import { setFbAuth } from "../../Reducer/fbSlice"
import { toast } from "react-toastify";
import { setUser } from "../../Reducer/authSlice";
import { secondUrl } from "../../Network/path";
import { useNavigate } from "react-router-dom";
import TwitterLogin from "./twitterLogin";

const Connection = () => {
    const [pages, setPages] = useState([]);
    const [instaAccounts, setInstaAccounts] = useState([])
    const fbAuth = JSON.parse(localStorage.getItem("fbAuth"));
    const fbSocialId = JSON.parse(localStorage.getItem("fbSocialId"))
    const [socialId, setSocialId] = useState(fbSocialId)
    const selector = useSelector((state) => state.auth)
    let bid = '';
    let wid = '';
    let code = '';
    const dispatch = useDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (fbAuth) {
            checkToken(fbAuth.access_token);
            getInstagramAccount({ accessToken: fbAuth.access_token })
        }
    }, []);

    const fetchPages = (access_token) => {
        window.FB.api(
            "me/accounts",
            { access_token: access_token },
            function (response) {
                if (response && !response.error) {
                    const new_pages = response.data
                    if (new_pages.length > 0) {
                        let pagesData = []
                        for (let i = 0; i < new_pages.length; i++) {
                            pagesData = [...pagesData, { connected_id: new_pages[i].id, name: new_pages[i].name, access_token: new_pages[i].access_token, social_id: socialId, status: "off" }]
                        }
                        setPages(pagesData)
                    }
                }
            }
        );
    };
    const handleLogin = async () => {
        window.FB.login(
            function (response) {
                if (response.authResponse) {
                    console.log(response)
                    const body = { type: "facebook", user_id: response.authResponse.userID, access_token: response.authResponse.accessToken }
                    addSocial(body)
                } else {
                    console.log("User cancelled login or did not fully authorize.");
                }
            },
            { scope: "email, pages_show_list,public_profile" }
        );
    };

    const addSocial = async (body) => {
        try {
            const res = await addSocialApi(body)
            if (res.data && res.data.status) {
                dispatch(setFbAuth(res.data.data))
                localStorage.setItem("fbAuth", JSON.stringify(res.data.data))
                fetchPages(res.data.data.access_token)
                localStorage.setItem("fbSocialId", res.data.social_id)
                setSocialId(res.data.social_id)
                dispatch(setUser(res.data.data))
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getInstagramAccount = async (body) => {
        try {
            const res = await axios.post(`${secondUrl}v7/instalogin`, body)
            if (res.data.status) {
                const accounts = res.data.data;
                console.log("------->>>>>>", accounts)
                if (accounts.length > 0) {
                    let new_accounts = []
                    for (let i = 0; i < accounts.length; i++) {
                        const obj = { connected_id: accounts[i].id, type: "instagram", name: accounts[i].name, access_token: accounts[i].access_token, status: "off" }
                        new_accounts = [...new_accounts, obj]
                    }
                    console.log(new_accounts, "new_accounts")
                    setInstaAccounts(new_accounts)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const checkToken = async (token) => {
        try {
            const res = await axios.get(
                `https://graph.facebook.com/debug_token?input_token=${token}&access_token=${token}`
            );
            if (res.data.data.is_valid) {
                fetchPages(token);
            } else {
                localStorage.removeItem("fbAuth");
            }
        } catch (error) {
            console.log(error);
            localStorage.removeItem("fbAuth");
        }
    };

    const handleClick = (el) => {
        try {
            let filter = pages.filter((page) => page.connected_id !== el.connected_id)
            filter = [...filter, { ...el, status: el.status === "on" ? "off" : "on" }]
            setPages(filter)
        } catch (error) {
            console.log(error)
        }
    }


    const connectPages = async () => {
        try {
            const body = { data: pages }
            const res = await linkSocialApi(body);
            if (res.data && res.data.status) {
                toast.success("pages connected")
            }
        } catch (error) {
            console.log(error)
        }
    }


    function connectWhatsApp() {
        // Conversion tracking code
        window.fbq && window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: '544704651303656', feature: 'whatsapp_embedded_signup' });

        // Launch Facebook login
        window.FB.login(function (response) {
            if (response.authResponse) {
                const code = response.authResponse.code;
                // The returned code must be transmitted to your backend, 
                // which will perform a server-to-server call from there to our servers for an access token
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: '401007423042134', // configuration ID goes here
            response_type: 'code',    // must be set to 'code' for System User access token
            override_default_response_type: true, // when true, any response types passed in the "response_type" will take precedence over the default types
            extras: {
                feature: "whatsapp_embedded_signup",
                sessionInfoVersion: 3  //  Receive Session Logging Info
            }
        });
    }

    const sessionInfoListener = async (event) => {

        if (event.origin == null) {
            return;
        }

        // Make sure the data is coming from facebook.com
        if (!event.origin.endsWith("facebook.com")) {
            return;
        }

        try {
            const data = JSON.parse(event.data);

            if (data.type === 'WA_EMBEDDED_SIGNUP') {
                // if user finishes the Embedded Signup flow
                if (data.event === 'FINISH') {
                    const { phone_number_id, waba_id } = data.data;
                    bid = phone_number_id;
                    wid = waba_id;
                    console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
                    const body = { code: code, id: phone_number_id, pin: '123456', wb_id: waba_id, name: "testing", phone: "5555555555" };
                    const connect = await wpConnect(body);
                    console.log("connect------------------------->", connect);
                }
                // if user reports an error during the Embedded Signup flow
                else if (data.event === 'ERROR') {
                    const { error_message } = data.data;
                    console.error("error ", error_message);
                }
                // if user cancels the Embedded Signup flow
                else {
                    const { current_step } = data.data;
                    console.warn("Cancel at ", current_step);
                }
            }
        } catch (e) {
            console.log('bid-------------------->', bid);
            console.log('wid-------------------->', wid);
            console.log('Non JSON Response', event.data);
            const params = new URLSearchParams(event.data);
            const jsonObject = await Object.fromEntries(params.entries());
            console.log('vvvvvvv-------------->', jsonObject);
            if (jsonObject.code) {
                code = jsonObject.code;
                const body = { code: jsonObject.code, id: bid, pin: '123456', wb_id: wid, name: "testing", phone: "5555555555" };
                const connect = await wpConnect(body);
                console.log("connect--------------2222----------->", connect);
            }
        }
    };
    window.addEventListener('message', sessionInfoListener);

    // const connectWhatsApp = async () => {
    //     try{

    //         window.FB.login(
    //             function (response) {
    //                 if (response.authResponse) {
    //                     console.log("response----------->",response);
    //                     // const body = { type: "facebook", user_id: response.authResponse.userID, access_token: response.authResponse.accessToken }
    //                     // addSocial(body)
    //                 } else {
    //                     console.log("User cancelled login or did not fully authorize.");
    //                 }
    //             },
    //             { scope: "email, pages_show_list,public_profile" }
    //         );
    //     }catch(e){
    //         console.log("e.message---------------->",e.message);
    //     }

    // }

    const handleInstagram = () => {
        window.FB.login(
            function (response) {
                if (response.authResponse) {

                    const body = { accessToken: response.authResponse.accessToken }
                    getInstagramAccount(body)
                } else {
                    console.log("User cancelled login or did not fully authorize.");
                }
            },
            { scope: "pages_show_list,instagram_basic,pages_read_engagement,instagram_manage_messages,pages_messaging,pages_manage_metadata,business_management" }
        );
    }



    const changeInstaStatus = async (el) => {
        try {
            let filter = instaAccounts.filter((page) => page.connected_id !== el.connected_id)
            filter = [...filter, { ...el, status: el.status === "on" ? "off" : "on" }]
            setInstaAccounts(filter)
        } catch (error) {
            console.log(error)
        }
    }

    const connectInstagram = async () => {
        try {
            const response = await linkInstagram(instaAccounts);
            console.log("---->>>>>--------->>>>>--------<<<<<<<>>>>>>>", response)
        } catch (error) {
            console.log(error)
        }
    }
    return (
        <div style={{ padding: "20px" }}>
            {!fbAuth && <button onClick={handleLogin}>Connect with FB</button>}

            {pages && pages.length > 0 && (
                <div>
                    <h3>Faceboook Pages</h3>
                    {pages.map((el) => (
                        <div>
                            <h4>{el.name}</h4>
                            <button onClick={() => handleClick(el)}>{el.status === "on" ? "disconnect" : "connect"}</button>
                        </div>
                    ))}
                    <button onClick={connectPages}>Connect Pages</button>
                </div>
            )}

            <hr />
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
                <button onClick={handleInstagram}>Connect With Instagram</button>
            </div>
            {instaAccounts && instaAccounts.length > 0 && instaAccounts.map((account) => (
                <div>
                    <h4>{`${account.name}`}</h4>
                    <button onClick={() => changeInstaStatus(account)}>{account.status === "on" ? "disconnect" : "connect"}</button>
                </div>
            ))}
            {instaAccounts.length > 0 && <button onClick={connectInstagram}>Connect Instagram accounts</button>}

            <hr />
            <div>
                <button onClick={connectWhatsApp}>WhatsApp</button>
            </div>

            <hr />
            <hr />
            <button onClick={() => navigate("/chat")}>Go to Chat page</button>

            <hr />
            <TwitterLogin />
        </div>
    );
};

export default Connection;
