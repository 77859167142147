import React from "react";
import "./AdminHome.css"; // Import the CSS file
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function AdminHome() {
  const auth = useSelector((state) => state.auth);

  console.log(auth);
  return (
    <div className="admin-home">
      <h1>Admin Dashboard</h1>
      <p>Welcome, Admin! Here you can manage the system.</p>
      {/* Add admin-specific features and tools here */}

      <Link to={"/connect"}>Connect</Link>
    </div>
  );
}

export default AdminHome;
