import React, { useEffect } from "react";
// import "./style.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import withAuthorization from "./Components/WithAuthorization";
import Register from "./Pages/Register";
import Login from "./Pages/Login";
import AdminHome from "./Pages/AdminHome";
import NotAuthorized from "./Pages/NotAuthorized";
import Chat from "./Pages/Chat";
import Connection from "./Pages/Connection";
import Header from "./Components/Header";
import PrivacyPolicy from "./Pages/PrivacyPolicy";
import TermsAndConditions from "./Pages/T&C";
import ConnectionStepForm from "./Pages/ConnectionStepForm";
import NewChat from "./Pages/NewChat";



const AdminHomeWithAuth = withAuthorization(AdminHome, "admin");
const ChatAuth = withAuthorization(Chat, "admin");
const ConnectionAuth = withAuthorization(Connection, "admin");
const ConnectionForm = withAuthorization(ConnectionStepForm, "admin")




function ChatWidget() {
  return (
    <div className="wrapper">
      <Header />
      <Routes>
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route path="/chat" element={<ChatAuth />} />
        <Route path="/" element={<AdminHomeWithAuth />} />
        <Route path="/not-authorized" element={<NotAuthorized />} />
        <Route path="/connect" element={<ConnectionAuth />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        <Route path="/connection-form" element={<ConnectionForm />} />
        <Route path="/new-chat" element={<NewChat />} />
      </Routes>
    </div>
  );
}

export default ChatWidget;
