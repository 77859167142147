import React from "react";
import ReactTimeAgo from "react-time-ago";
import $ from "jquery";
const ChatUser = ({data,click}) => {
    const ico = (type)=>{
        switch (type) {
            case 'whatsApp':
                return "bi bi-whatsapp";
            case 'facebook':
                return "bi bi-facebook";
            case 'instagram':
                return "bi bi-instagram";
            default:
                return "bi bi-chat-dots";
        }
    }

  return (
            <li className="list-group-item d-flex justify-content-between align-items-center offline active"
            data-user="1">
            <div className="d-flex align-items-center w-100" onClick={() => {
            $(`.us`).removeClass("active");
            $(`.usr${data.id}`).addClass("active");
            click(data);
            }}>
            <div className="green-status">
                <div className={data.current_status === "online" ? "green-dot" : "gray-dot"}></div>
                {data.image ? <img
                src={data.image}
                alt="Jhon"
                className="rounded-circle"
                style={{ width: "50px" }}
                />:<span className="dyna-user">{data.name.substring(0, 1)}</span> }
            </div>
            <div className="w-100 ms-3">
                <h6>
                {data.name} 

                <span className="d-flex flex-column justify-content-center align-items-center">
                <small className="time">
                    <ReactTimeAgo
                    date={data.unix_time * 1000}
                    locale="en-US"
                    timeStyle="twitter-minute-now"
                    />
                </small>
                <span className="mt-2"><i className={ico(data.type)}></i></span>
                </span>
                </h6>
                <p className="chatlist-sms">{data.message}</p>
                {/* <small className={`active-${data.current_status === "online" ? "active" : "offline"}`}>{data.current_status === "online" ? "Active" : "Offline"}</small> */}
            </div>
        </div>
        </li>
    );
};

export default ChatUser;

