import React from "react";

const TermsAndConditions = () => {
    return (
        <div >
            <h1 >Terms and Conditions</h1>
            <p>Last updated: 03 Oct 2024</p>

            <section className="mt-6">
                <h2 className="text-xl font-semibold mb-2">1. Acceptance of Terms</h2>
                <p>
                    By downloading, installing, or using our App, you agree to these Terms.
                    If you are using the App or Site on behalf of an organization, you are
                    agreeing to these Terms on behalf of that organization, and you
                    represent that you have the authority to bind that organization to
                    these Terms.
                </p>
            </section>

            <section className="mt-6">
                <h2 className="text-xl font-semibold mb-2">2. Changes to the Terms</h2>
                <p>
                    We reserve the right, at our sole discretion, to modify or replace these
                    Terms at any time. We will notify you of any changes by updating the
                    "Last Updated" date at the top of this page. Your continued use of the
                    App or Site following the posting of any changes means you accept those
                    changes.
                </p>
            </section>

            <section className="mt-6">
                <h2 className="text-xl font-semibold mb-2">3. Use of the App and Site</h2>
                <p>
                    You agree to use the App and Site only for lawful purposes. You are
                    prohibited from using the App or Site to engage in any activity that
                    could harm us or others or that violates any law.
                </p>
            </section>

            <section className="mt-6">
                <h2 className="text-xl font-semibold mb-2">4. User Accounts</h2>
                <p>
                    To use certain features of the App or Site, you may be required to create
                    an account. You are responsible for maintaining the confidentiality of
                    your account and password and for restricting access to your device.
                </p>
            </section>

            <section className="mt-6">
                <h2 className="text-xl font-semibold mb-2">5. Termination</h2>
                <p>
                    We reserve the right to terminate or suspend your account at any time,
                    without prior notice or liability, if you breach these Terms or engage in
                    unauthorized or unlawful use of the App or Site.
                </p>
            </section>

            <section className="mt-6">
                <h2 className="text-xl font-semibold mb-2">6. Limitation of Liability</h2>
                <p>
                    To the maximum extent permitted by law, we shall not be liable for any
                    damages arising from your use of the App or Site.
                </p>
            </section>

            <section className="mt-6">
                <h2 className="text-xl font-semibold mb-2">7. Contact Us</h2>
                <p>
                    If you have any questions about these Terms, please contact us at sachin.digittrix@gmail.com
                </p>
            </section>

            <p className="mt-6">
                By using our App or Site, you acknowledge that you have read, understood,
                and agree to be bound by these Terms and Conditions.
            </p>
        </div>
    );
};

export default TermsAndConditions;
