import React from "react";

const ProfileForm = ({
  image,
  setImage,
  callAvail,
  setCallAvail,
  name,
  setName,
  title,
  setTitle,
  welcome,
  setWelcome,
}) => {
  return (
    <>
      <h3 class="chat5-head">Who will your visitor meet?</h3>
      <form class="chat5">
        <div class="row">
          <div class="col-md-6">
            <label for="fname">Name</label>
            <input
              type="text"
              id="fname"
              name="fname"
              value={name}
              placeholder="Enter your name"
              onChange={(e) => setName(e.target.value)}
            />
            <p>
              People visiting your website will see that. Better use a full real
              name.
            </p>
          </div>
          <div class="col-md-6">
            <label for="ftname">Title</label>
            <input
              type="text"
              id="fname"
              name="ftname"
              value={title}
              placeholder="Enter your title"
              onChange={(e) => setTitle(e.target.value)}
            />
            <p>Will show under your name when you join a conversation.</p>
          </div>
        </div>
      </form>
      <div class="chat5-choose mt-4">
        <div class="d-flex justify-content-start align-items-center">
          <img src="../assets/images/social-user.png" />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
          />
          <p class="m-0">Choose an image</p>
        </div>
      </div>
      <p class="choose-p">
        A friendly, informal photo is more trustworthy than a company logo or a
        stock photo of a smiling support rep.
      </p>

      <div class="form-check form-switch">
        <input
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckDefault"
          checked={welcome}
          onChange={() => setWelcome(!welcome)}
        />
        <label class="form-check-label" for="flexSwitchCheckDefault">
          Show me on the Welcome screen on the chat window
        </label>
      </div>

      <div class="form-check form-switch mt-3">
        <input
          class="form-check-input"
          type="checkbox"
          id="flexSwitchCheckChecked1"
          checked={callAvail}
          onChange={() => setCallAvail(!callAvail)}
        />
        <label class="form-check-label mb-3" for="flexSwitchCheckChecked1">
          Call Availability
        </label>
      </div>
    </>
  );
};

export default ProfileForm;
