import React from "react";
import { Link } from "react-router-dom";

const StepsHeader = ({ step, nextStep, prevStep }) => {
  return (
    <>
      <Link className="d-flex align-items-center w-100">
        <div className="icon-border" onClick={prevStep}>
          <i className="fa fa-angle-left" aria-hidden="true"></i>
        </div>
        {step === 1 ? "Back" : "Chat/Call Widget"}
        {step > 1 && (
          <button
            className="chat-top-btn"
            style={{ float: "right" }}
            onClick={nextStep}
          >
            Continue
          </button>
        )}
      </Link>
      {step > 1 && (
        <>
          {" "}
          <p className="top-p">
            Talk to your website visitors, send friendly invites, and set up
            smart chatbot interactions.
          </p>
          <div className="container mt-4">
            <div className="step-container">
              <div className="step active">
                <div className="step-indicator"></div>
                <div className="step-title">Customization</div>
              </div>
              <hr />

              {/* <!-- Step 2 --> */}
              <div className={`step ${step > 2 && "active"}`}>
                <div className="step-indicator"></div>
                <div className="step-title">Profile</div>
              </div>

              <hr />

              {/* <!-- Step 3 --> */}
              <div className={`step ${step > 3 && "active"}`}>
                <div className="step-indicator"></div>
                <div className="step-title">Installation</div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default StepsHeader;
