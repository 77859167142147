import React, { useEffect, useState } from "react";
import Input from "../../Components/UI/input";
import { useForm } from "react-hook-form";
import { secondUrl } from "../../Network/path";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import SocialLogin from "./socialLogin";
import { yupResolver } from "@hookform/resolvers/yup";
import { loginSchema } from "../../Helpers/formSchema";
import toast from "react-hot-toast";
import Button from "../../Components/UI/button";
import { resendOtp } from "../../Services/api";
import { useDispatch } from "react-redux";
import { setToken, setUserDetails } from "../../Reducer/authSlice";
import moment from "moment";

const LoginForm = () => {
  const [otpStatus, setOtpStatus] = useState(false);
  const [remainingSeconds, setRemainingSeconds] = useState(120);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(loginSchema),
    mode: "onChange",
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (otpStatus) {
      const intervalId = startTimer();
      // Cleanup function to clear the interval on unmount
      return () => clearInterval(intervalId);
    }
  }, [otpStatus, remainingSeconds]);

  const startTimer = () => {
    let intervalId = setInterval(() => {
      if (remainingSeconds > 0) {
        setRemainingSeconds(remainingSeconds - 1);
      } else {
        clearInterval(intervalId); // Stop timer when it reaches 0
      }
    }, 1000);

    return intervalId;
  };

  const onSubmit = async (data) => {
    try {
      const res = await axios.post(`${secondUrl}v1/verifyotp`, {
        email: data.email,
        otp: data.otp,
        platform: "web",
        login_type: "chatwidget",
        type: "login",
        device_id: "",
        device_token: "",
        unix_time: moment().unix(),
      });

      if (res.data && res.data.status === "success") {
        localStorage.setItem("tokenk", res.data.token);
        dispatch(setToken(res.data.token));
        dispatch(setUserDetails(res.data.data));
        navigate("/connection-form");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong", {
        position: "bottom-center",
      });
    }
  };

  const otpHandler = async () => {
    try {
      const email = getValues("email");
      if (!email) {
        toast.error("Email is required", {
          position: "bottom-center",
        });
        return;
      }
      const res = await axios.post(`${secondUrl}v1/login`, {
        email,
      });
      if (res.data && res.data.status === "success") {
        toast.success("Otp Sent", {
          position: "bottom-center",
        });
        setOtpStatus(true);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong", {
        position: "bottom-center",
      });
    }
  };

  const resendOtpHandler = async () => {
    try {
      const email = getValues("email");
      const data = { email: email };
      const res = await resendOtp(data);
      if (res.data && res.data.status === "success") {
        toast.success("Otp Sent");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong", {
        position: "bottom-center",
      });
    }
  };
  return (
    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-12">
      <div className="form-section">
        <h2>Login</h2>
        <p>Enter your account details </p>
        <form className="form-ui" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-column-al">
            <label>Email </label>
          </div>
          <Input
            parentClass={"inputForm-al"}
            inputClass={"input-al"}
            placeholder={"bill.sanders@example.com"}
            type={"text"}
            name={"email"}
            event={register}
            error={errors}
          >
            <div className="send-otp" onClick={otpHandler}>
              <span className="otp-ui">SEND OTP</span>
            </div>
          </Input>

          <div className="flex-column-al">
            <label>Enter OTP </label>
          </div>

          <Input
            parentClass={"inputForm-al"}
            inputClass={"input-al"}
            type={"text"}
            name={"otp"}
            event={register}
            error={errors}
            disabled={!otpStatus}
          >
            <div className="send-otp">
              {remainingSeconds > 0 ? (
                <span className="otp-ui ms-2 otp-left">
                  ({remainingSeconds}s)
                </span>
              ) : (
                <span
                  className="otp-ui ms-2 otp-left"
                  onClick={resendOtpHandler}
                >
                  RESEND
                </span>
              )}
            </div>
          </Input>
          {/* <p className="p-form p-text-send">
            {" "}
            We’ve sent the OTP to your number 3 times For your security, we
            can’t send it again{" "}
          </p> */}
          <button className="button-submit" type="submit">
            Login
          </button>

          <div className="separator">
            <hr className="line" />
            <span>Or</span>
            <hr className="line" />
          </div>
          <SocialLogin />
          <div className="flex-row">
            <div>
              <label>Don’t have an account?</label>
            </div>
            <span className="span">
              <Button
                type={"button"}
                className={"btn btn-warning button-warning"}
                onClick={() => navigate("/register")}
              >
                Sign up
              </Button>
            </span>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
