import React, { useEffect, useState } from "react";
import WidgetFormOne from "./components/widgetForm1";
import StepsHeader from "./components/stepsHeader";
import Customization from "./components/customizationForm";
import toast from "react-hot-toast";
import { addConnectionApi, getDetailsWithKeyApi } from "../../../Services/api";
import { useNavigate } from "react-router-dom";
import ProfileForm from "./components/profileForm";
import Installation from "./components/installation";
import { useSelector } from "react-redux";

const ChatWidgetForm = () => {
  const [step, setStep] = useState(1);
  const [headerColor, setHeaderColor] = useState("#ffc300");
  const [iconPlacement, setIconPlacement] = useState("left");
  const [selectedIcon, setSelectedIcon] = useState("bi bi-chat-fill");
  const [iconType, setIconType] = useState("icon");
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [image, setImage] = useState("");
  const [welcome, setWelcome] = useState(false);
  const [callAvail, setCallAvail] = useState(false);

  const { userDetails } = useSelector((state) => state.auth);

  const navigate = useNavigate();

  const nextStep = () => {
    switch (step) {
      case 1: {
        setStep(step + 1);
        break;
      }
      case 2: {
        addConnection();
        break;
      }
      case 3: {
        addConnection();
        break;
      }
      case 4: {
        navigate("/");
        break;
      }
    }
  };
  const prevStep = () => {
    if (step > 1) {
      setStep((prev) => prev - 1);
    }
  };

  const addConnection = async () => {
    try {
      let formData = new FormData();
      formData.append("color_code", headerColor);
      formData.append("font_color", "#ffffff");
      formData.append("chat_position", iconPlacement);
      formData.append("chat_mode", iconType);
      formData.append("chat_text", iconType === "icon" ? selectedIcon : "");
      formData.append("name", name);
      formData.append("title", title);
      formData.append("image", image);
      formData.append("welcome", welcome ? "on" : "off");
      formData.append("callAvail", callAvail ? "on" : "off");
      const res = await addConnectionApi(formData);
      if (res && res.data.status) {
        setStep(step + 1);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getChatWidgetDetails = async () => {
    try {
      const body = { key: userDetails.key };
      const res = await getDetailsWithKeyApi(body);

      if (res.data && res.data.status === "success") {
        const widget_info = res.data.data.widget;
        if (widget_info && widget_info.id) {
          setHeaderColor(widget_info.color_code);
          setIconPlacement(widget_info.chat_position);
          setIconType(widget_info.chat_mode);
          setSelectedIcon(widget_info.chat_text);
          setName(widget_info.name);
          setTitle(widget_info.title);
          setWelcome(widget_info.wellcome === "on" ? true : false);
          setCallAvail(widget_info.call_avail === "on" ? true : false);
          if (!widget_info.name) {
            setStep(3);
          } else {
            setStep(4);
          }
        } else {
          setStep(1);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getChatWidgetDetails();
  }, []);
  return (
    <>
      <div className="col-lg-8">
        <div className="main-chat-right">
          <StepsHeader step={step} nextStep={nextStep} prevStep={prevStep} />
          {step === 1 && <WidgetFormOne nextStep={nextStep} />}
          {step === 2 && (
            <Customization
              nextStep={nextStep}
              headerColor={headerColor}
              setHeaderColor={setHeaderColor}
              iconType={iconType}
              setIconType={setIconType}
              iconPlacement={iconPlacement}
              setIconPlacement={setIconPlacement}
              selectedIcon={selectedIcon}
              setSelectedIcon={setSelectedIcon}
            />
          )}
          {step === 3 && (
            <ProfileForm
              name={name}
              setName={setName}
              title={title}
              setTitle={setTitle}
              image={image}
              setImage={setImage}
              welcome={welcome}
              setWelcome={setWelcome}
              callAvail={callAvail}
              setCallAvail={setCallAvail}
            />
          )}
          {step === 4 && <Installation />}
        </div>
      </div>
    </>
  );
};

export default ChatWidgetForm;
