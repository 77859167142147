import React, { useContext } from "react";
import { SocketContext } from "../../../../Context";

const ProfileSection = () => {
  const { currentUser } = useContext(SocketContext);

  console.log("first", currentUser);
  return (
    <div class="col-2 end-section bg-white p-3">
      <div class="user-info">
        <img
          src="../assets/images/social-john.png"
          alt="Jhon"
          class="rounded-circle m-auto d-flex mb-3"
          style={{ width: "50px" }}
        />
        <h5 class="text-center">{currentUser.name}</h5>

        <form class="mt-4">
          <div class="mb-3">
            <input type="email" class="form-control" placeholder="Email" />
          </div>
          <div class="mb-3">
            <input type="tel" class="form-control" placeholder="Phone number" />
          </div>
          <div class="mb-3">
            <textarea
              class="form-control"
              rows="3"
              placeholder="Notes"
            ></textarea>
          </div>
        </form>
        <p class="text-muted small">Only Visible To Agents</p>
        <div class="mt-3">
          <p class="type-p">
            Device Type<span>Windows 11</span>
          </p>
          <p class="footer-p1">Channel</p>
          <p class="footer-p2">
            <img src="./images/social-fb.png" class="img-fluid" />
            <a href="#"> John Bieber 2 - For Business</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProfileSection;
