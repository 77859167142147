import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      <h1>Privacy Policy</h1>
      <p>
        <strong>Effective Date:</strong> 23 september 2024
      </p>

      <p>
        Thank you for using our application. We value your privacy and are
        committed to protecting your personal information. This Privacy Policy
        explains how we collect, use, and protect your information when you use
        our app.
      </p>

      <h2>1. Information We Collect</h2>
      <p>
        When you interact with our app through Facebook, we may collect the
        following types of information:
      </p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Your name, email address,
          profile picture, and other profile details provided by Facebook when
          you use Facebook Login.
        </li>
        <li>
          <strong>Usage Data:</strong> Information about how you interact with
          our app, including clicks, actions, and general usage statistics.
        </li>
      </ul>

      <h2>2. How We Use Your Information</h2>
      <p>We may use your information for the following purposes:</p>
      <ul>
        <li>To authenticate and verify your identity.</li>
        <li>To provide you with access to our app's features and services.</li>
        <li>To personalize your experience within the app.</li>
        <li>To improve the performance and functionality of our app.</li>
      </ul>

      <h2>3. Sharing of Information</h2>
      <p>
        We do not sell or share your personal information with third parties,
        except:
      </p>
      <ul>
        <li>
          With service providers who help us operate and maintain our app.
        </li>
        <li>
          To comply with legal obligations or protect the rights, property, or
          safety of our users.
        </li>
      </ul>

      <h2>4. Data Security</h2>
      <p>
        We take appropriate security measures to protect your information from
        unauthorized access or disclosure. However, no data transmission over
        the internet or method of electronic storage is completely secure. We
        cannot guarantee absolute security.
      </p>

      <h2>5. Your Choices</h2>
      <p>You have control over your personal information and can:</p>
      <ul>
        <li>
          Update or correct your Facebook profile information at any time.
        </li>
        <li>
          Revoke the app's access to your Facebook account from your Facebook
          settings.
        </li>
      </ul>

      <h2>6. Changes to this Policy</h2>
      <p>
        We may update this Privacy Policy from time to time. Any changes will be
        posted on this page, and we encourage you to review it regularly.
      </p>

      <h2>7. Contact Us</h2>
      <p>
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <ul>
        <li>
          <strong>Email:</strong> sachin.digittrix@gmail.com
        </li>
      </ul>
    </div>
  );
};

export default PrivacyPolicy;
