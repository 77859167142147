import React, { useEffect, useState } from "react";
import { getTemplateList, send_sms, wpConnect } from "../../../Services/api";
import { getConnection } from "../../../Reducer/whatsAppSlice";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
// import LeftSide from "./components/leftSide";


const WhatsApp = () => {
    const media = ['IMAGE','VIDEO','DOCUMENT'];
    const dispatch = useDispatch();
    const selector = useSelector((state) => state.whatsApp);
    const [selectObj, setSelectedObj] = useState(null);
    const [template, setTemplate] = useState(null);
    const [fields, setFields] = useState([]);
    const [phone, setPhone] = useState('');
    const [selectedValue, setSelectedValue] = useState(null);
    const [tableList,setTable] =useState([]);
    let bid = '';
    let wid = '';
    let code = '';
    useEffect(()=>{
        dispatch(getConnection());
    },[])


    const [selectedRow, setSelectedRow] = useState(null); // State to track selected row

    // List of objects to display in the table

  
    // Function to handle row selection
    const handleRowClick = (item) => {
      setSelectedRow(item); // Set the clicked row as selected
      makeTemplate(item);
    };

    const handleInputChange = (index, event) => {
        const newFields = [...fields];
        newFields[index].value = event.target.value;
        setFields(newFields);
        console.log(newFields);
      };

    const handleSelectionChange = async(event) => {
        console.log("<-----------selector.connections--------->",selector.connections);
        console.log(event.target.value);
        const selected = selector.connections.find(option => option.id === Number(event.target.value));
        setSelectedValue(event.target.value);
        setSelectedObj(selected);
        console.log("<---------selected-------->",selected);
        const send={id:selected.wb_id,token:selected.access_token};
        console.log("<---------send-------->",send);
        const sc = await getTemplateList(send);
        console.log("<-------sc------->",sc.data.data);
        setTable(sc.data.data);

//         let p = [{name:"lmo",value:"one"},{name:"xyz",value:"opi"}];
// let x = [{name:"lmo",take:"one"},{name:"xyz",take:"opi"}];
// for (let index = 0; index < x.length; index++) {
//     x[index].take=p[0].value;
//     p.splice(0, 1);
// }
// console.log('p---------->',x);

    };

    // const handleInputChange = (event) => {
    //     const { id, value } = event.target;
    //     setFormData({
    //       ...formData,
    //       [id]: value
    //     });
    //   };

    const makeTemplate = (data)=>{
        const comp=data.components;
        let px=fields;
        let makeNew = [];
        let form=[];
        for (let index = 0; index < comp.length; index++) {
                switch (comp[index].type) {
                    case 'HEADER':
                    let head = {"type": "header","parameters": []}
                    let name = comp[index].format.toLowerCase();
                    if(media.includes(comp[index].format)){
                      
                        form.push({'name':`${name} url`,'input':'text','value':''});
                        head.parameters.push({
                        "type": name,
                        [name]: {
                            "link": px.length>0 ? px[0].value:"",
                        }
                        });
                        px.length>0 && px.splice(0, 1);
                        makeNew.push(head);
                    }

                    if(comp[index].format=='LOCATION'){
                    {
                        head.parameters.push({ "type": name,
                        [name]: {
                            "longitude": px.length>0 ? px[0].value:"",
                            "latitude": px.length>1 ? px[1].value:"",
                            "name": px.length>2 ? px[2].value:"",
                            "address":px.length>3 ? px[3].value:"",
                        }});
                        px.length>0 && px.splice(0, 1);
                        px.length>0 && px.splice(0, 1);
                        px.length>0 && px.splice(0, 1);
                        px.length>0 && px.splice(0, 1);
                        form.push({'name':`${name} longitude`,'input':'text','value':''});
                        form.push({'name':`${name} latitude`,'input':'text','value':''});
                        form.push({'name':`${name} name`,'input':'text','value':''});
                        form.push({'name':`${name} address`,'input':'text','value':''});
                    };

                    }
                        break;
                        case 'BODY':
                            let bdy={
                                "type": "body",
                                "parameters": [
                                ]
                              }
                            if(comp[index].example && comp[index].example.body_text && comp[index].example.body_text.length>0){
                                for (let ind = 0; ind < comp[index].example.body_text[0].length; ind++) {
                                    form.push({'name':`${comp[index].example.body_text[0][ind]}`,'input':'text','value':''});
                                    bdy.parameters.push({
                                        "type": "text",
                                        "text": px.length>0 ? px[0].value:""
                                      });
                                      px.length>0 && px.splice(0, 1);
                                      
                                }
                                makeNew.push(bdy);
                            }
                            
                        break;

                        case 'BUTTON':
                            if(comp[index].buttons.length>0){
                                for (let indx = 0; indx < comp[index].buttons.length; indx++) {
                                   if(comp[index].buttons[indx].example && comp[index].buttons[indx].example.length>0);
                                   let type=comp[index].buttons[indx].type.toLowerCase();
                                    makeNew.push({
                                        "type": "button",
                                        "sub_type": type,
                                        "index": indx,
                                        "parameters": [
                                        {
                                            "type": type,
                                            [type]: px.length>0 ? px[0].value:""
                                        }
                                        ]
                                    });
                                    px.length>0 && px.splice(0, 1);
                                    form.push({'name':`Button ${type} text`,'input':'text','value':''});
                                }
                            }
                     
                        
                        break;
                
                    default:
                        break;
                }
            
        }
        setFields(form);
        const nt ={
            "name": data.name,
            "language": {
              "code": data.language
            },
            "components":makeNew
        };
        // if(fields.length>0){
        //     setTemplate();
        // }
        console.log("makeNew---------->",nt);
        console.log("form--------------->",form);
      return nt;

    }
    
      const handleSubmit = async(event) => {
        event.preventDefault();
        console.log('under submit11',selectedRow);
        const templateN = await makeTemplate(selectedRow);
        console.log('under submit22',selectedRow);
        let body={temp:templateN,id:selectObj.phone_number_id,token:selectObj.access_token,sms:"template",type:"template",con_id:selectObj.id,phone:phone};
        console.log("body--------->",body);
        const els = await send_sms(body);
        console.log('els----------------->',els);

        // Sample request payload for WhatsApp Graph API
        // const payload = {
        //   messaging_product: "whatsapp",
        //   to: "recipient_phone_number", // Replace with the actual recipient's number
        //   type: "template",
        //   template: {
        //     name: selectedRow.name.toLowerCase().replace(/\s+/g, "_"),
        //     language: { code: "en_US" },
        //     components: [
        //       {
        //         type: "body",
        //         parameters: selectedRow.fields.map((field) => ({
        //           type: "text",
        //           text: formData[field.id]
        //         }))
        //       }
        //     ]
        //   }
        // };
    
        // console.log("Sending payload to WhatsApp API:", payload);
    
        // Make an API request to WhatsApp Graph API
        // Example POST request with fetch:
        // fetch('https://graph.facebook.com/v12.0/<whatsapp_business_account_id>/messages', {
        //   method: 'POST',
        //   headers: {
        //     'Authorization': `Bearer <access_token>`,
        //     'Content-Type': 'application/json',
        //   },
        //   body: JSON.stringify(payload),
        // }).then(response => response.json())
        //   .then(data => console.log("Message sent successfully:", data))
        //   .catch(error => console.error("Error sending message:", error));
      };
    function connectWhatsApp() {
        window.fbq && window.fbq('trackCustom', 'WhatsAppOnboardingStart', { appId: '544704651303656', feature: 'whatsapp_embedded_signup' });
        window.FB.login(function (response) {
            if (response.authResponse) {
                const code = response.authResponse.code;
            } else {
                console.log('User cancelled login or did not fully authorize.');
            }
        }, {
            config_id: '401007423042134', // configuration ID goes here
            response_type: 'code',    // must be set to 'code' for System User access token
            override_default_response_type: true, // when true, any response types passed in the 
            extras: {
                feature: "whatsapp_embedded_signup",
                sessionInfoVersion: 3  //  Receive Session Logging Info
            }
        });
    }

    const sessionInfoListener = async (event) => {

        if (event.origin == null) {
            return;
        }
        if (!event.origin.endsWith("facebook.com")) {
            return;
        }

        try {
            const data = JSON.parse(event.data);
            if (data.type === 'WA_EMBEDDED_SIGNUP') {
                if (data.event === 'FINISH') {
                    const { phone_number_id, waba_id } = data.data;
                    bid = phone_number_id;
                    wid = waba_id;
                    console.log("Phone number ID ", phone_number_id, " WhatsApp business account ID ", waba_id);
                    const body = { code: code, id: phone_number_id, pin: '123456', wb_id: waba_id, name: "testing", phone: "5555555555" };
                    const connect = await wpConnect(body);
                    console.log("connect------------------------->", connect);
                }
                else if (data.event === 'ERROR') {
                    const { error_message } = data.data;
                    console.error("error ", error_message);
                }
                else {
                    const { current_step } = data.data;
                    console.warn("Cancel at ", current_step);
                }
            }
        } catch (e) {
            const params = new URLSearchParams(event.data);
            const jsonObject = await Object.fromEntries(params.entries());
            if (jsonObject.code) {
                code = jsonObject.code;
                const body = { code: jsonObject.code, id: bid, pin: '123456', wb_id: wid, name: "testing", phone: "5555555555" };
                const connect = await wpConnect(body); //add api for save your number
            }
        }
    };
    window.addEventListener('message', sessionInfoListener);

    // 'templates'

  return (
    <section class="social-main">
      <div class="container">
        <div class="row justify-content-center align-items-start">
        <button onClick={connectWhatsApp}>WhatsApp</button>
        </div>
      </div>

    <div className="selection-container">
      <h3>Select an option:</h3>
      <div className="custom-select">
        <select value={selectedValue} onChange={handleSelectionChange}>
          <option value="">-- Select an option --</option>
          {selector.connections.length>0 && selector.connections.map((message) => (
          <option key={message.id} value={message.id}>{message.name}</option>
          ))}
        </select>
      </div>

      {selectedValue && (
        <p className="selection-output">
          You selected: <strong>{selectedValue}</strong>
        </p>
      )}
    </div>

    <div className="table-container">
      <h3>Select a row from the table:</h3>
      <table className="data-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Category</th>
            <th>Sub Category</th>
            <th>Language</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {tableList.map((item) => (
            <tr
              key={item.id}
              className={selectedRow && selectedRow.id === item.id ? "selected" : ""}
              onClick={() => handleRowClick(item)}
            >
              <td>{item.id}</td>
              <td>{item.name}</td>
              <td>{item.category}</td>
              <td>{item.sub_category}</td>
              <td>{item.language}</td>
              <td>{item.status}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedRow && (
        <div className="selection-output">
          <h4>You selected:</h4>
          <p><strong>Name:</strong> {selectedRow.name}</p>
        </div>
      )}
    </div>

    <div className="whatsapp-form-container">
      <h3>Send WhatsApp Message</h3>

<form onSubmit={handleSubmit}>
{fields.map((field, index) => (
        <div key={index} className="form-group">
          <label htmlFor={field.name}>{field.name.replace('_', ' ')}:</label>
          <input
            type={field.input}
            name={field.name}
            value={field.value}
            onChange={(e) => handleInputChange(index, e)}
          />
        </div>
      ))}
           <div className="form-group">
        <label htmlFor={"phone"}>Phone number:</label>
                <PhoneInput
                country={'in'}  // Default country
                value={phone}
                onChange={setPhone}  // Updates phone state on change
                inputStyle={{
                width: '300px',
                padding: '10px',
                }}
                />
            </div>

        {/* Submit Button */}
        {selectedRow && (
          <div className="form-group">
            <button type="submit">Send Message</button>
          </div>
        )}
</form>
    </div>


    </section>
  );
};

export default WhatsApp;

