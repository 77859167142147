import { useContext, useEffect, useState } from "react";
import View from "./view";
import Example from "../../Example";
import { SocketContext } from "../../../Context";
import "./index.css";

const Notifications = () => {
  const { call, access } = useContext(SocketContext);
  const [userModel, setUserModel] = useState(call.isReceivingCall);
  useEffect(() => {
    setUserModel(call.isReceivingCall);
  }, [call]);

  return (
    <>
      {call.isReceivingCall == true && !access && (
        <Example
          show={userModel}
          onHide={() => setUserModel(!userModel)}
          component={<View onHide={() => setUserModel(!userModel)} />}
          backDrop={"static"}
          style={!call.show ? "viewpro Incomming d-none" : "viewpro Incomming"}
        />
      )}
    </>
  );
};
export default Notifications;
