import React from 'react'
import { secondUrl } from '../../Network/path'
import axios from 'axios'

const TwitterLogin = () => {
    const token = localStorage.getItem("tokenk")

    const handleTwitter = async () => {
        window.open(`${secondUrl}v8/auth/twitter?token=${token}`, "_blank")
    }
    return (
        <div>
            <h1>Twitter Authentication</h1>
            <button onClick={handleTwitter}>Login with Twitter</button>
        </div>
    );
}

export default TwitterLogin