import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { existingConnectionApi } from "../Services/api";


const getConnectedPages = createAsyncThunk("connectedPages", async (_, { rejectWithValue }) => {
    try {
        const res = await existingConnectionApi();
        return res.data
    } catch (error) {
        return rejectWithValue(error.response)
    }
})

const fbSlice = createSlice({
    name: "fbSlice",
    initialState: {
        submitting: false,
        error: null,
        success: false,
        fbPages: [],
        fbAccessToken: "",
        fbAuth: {}
    },
    reducers: {
        setFbAuth: (state, action) => {
            state.fbAuth = action.payload
        }
    },
    extraReducers: (buider) => {
        buider
            .addCase(getConnectedPages.pending, (state, action) => {
                state.submitting = true;
                state.error = null
                state.success = false
            })
            .addCase(getConnectedPages.fulfilled, (state, action) => {
                state.submitting = false
                state.error = null
                state.success = true
                state.fbPages = action.payload
            })
            .addCase(getConnectedPages.rejected, (state, action) => {
                state.submitting = false
                state.error = action.payload
                state.success = false
            })
    }
})

export const { setFbAuth } = fbSlice.actions
export default fbSlice.reducer;