import React from "react";

const LeftSide = () => {
  return (
    <div class="col-lg-4">
      <div class="main-left">
        <img src="../assets/images/social-main-img.png" class="img-fluid" />
        <h1>
          “Connect All Your Social Media in <span>One Place”</span>
        </h1>
      </div>
    </div>
  );
};

export default LeftSide;
