import { combineReducers } from "@reduxjs/toolkit";
import authSlice from "./Reducer/authSlice.js";
import whatsAppSlice from "./Reducer/whatsAppSlice.js";
import fbSlice from "./Reducer/fbSlice.js"

const rootReducer = combineReducers({
  auth: authSlice,
  whatsApp: whatsAppSlice,
  fb: fbSlice,
});

export default rootReducer;
