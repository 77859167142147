import React from "react";
import { Link } from "react-router-dom";

const WidgetFormOne = ({ nextStep }) => {
  return (
    <div className="chat-content d-flex flex-column justify-content-center align-items-center">
      <div className="chat-head d-flex justify-content-center align-items-center">
        {" "}
        <img src="../assets/images/social-msg.png" className="img-fluid" />
        <h2>Chat/Call Widget</h2>
      </div>
      <p className="text-center">
        Talk to your website visitors, send friendly invites, and set up smart
        chatbot interactions.
      </p>
      <Link onClick={nextStep}>
        <button>Install the chat widget</button>
      </Link>
    </div>
  );
};

export default WidgetFormOne;
