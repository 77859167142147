import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import LeftSide from "./components/leftSide";
import StepOne from "./components/step1";
import StepTwo from "./components/step2";
import StepThree from "./components/step3";
import StepFour from "./components/step4";
import StepFive from "./components/step5";

import { addConnectionApi } from "../../Services/api";
import toast from "react-hot-toast";
import ChatWidgetForm from "./ChatWidget";

const ConnectionStepForm = () => {
  const [type, setType] = useState("");
  const [step, setStep] = useState(1);

  const navigate = useNavigate();

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };

  return (
    <section class="social-main">
      <div class="container">
        <div class="row justify-content-center align-items-start">
          <LeftSide />
          {step === 1 && <StepOne nextStep={nextStep} setType={setType} />}
          {step === 2 && type === "widget" && <ChatWidgetForm />}
        </div>
      </div>
    </section>
  );
};

export default ConnectionStepForm;
