import React, { useState } from "react";
import { addSocialApi, linkSocialApi } from "../../../Services/api";
import { useDispatch } from "react-redux";
import { setFbAuth } from "../../../Reducer/fbSlice";
import { setUser } from "../../../Reducer/authSlice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const FacebookConnection = ({setCurrentTab}) => {
  const fbSocialId = JSON.parse(localStorage.getItem("fbSocialId"));
  const [pages, setPages] = useState([]);
  const [socialId, setSocialId] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const fbAuth = JSON.parse(localStorage.getItem("fbAuth"));

  const handleLogin = async () => {
    window.FB.login(
      function (response) {
        if (response.authResponse) {
          const body = {
            type: "facebook",
            user_id: response.authResponse.userID,
            access_token: response.authResponse.accessToken,
          };
          addSocial(body);
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      { scope: "email, pages_show_list,public_profile" }
    );
  };

  const addSocial = async (body) => {
    try {
      const res = await addSocialApi(body);
      if (res.data && res.data.status) {
        dispatch(setFbAuth(res.data.data));
        localStorage.setItem("fbAuth", JSON.stringify(res.data.data));
        localStorage.setItem("fbSocialId", res.data.social_id);
        setSocialId(res.data.social_id);
        dispatch(setUser(res.data.data));
        fetchPages(res.data.data.access_token, res.data.social_id);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const fetchPages = (access_token, fbId) => {
    window.FB.api(
      "me/accounts",
      { access_token: access_token },
      function (response) {
        if (response && !response.error) {
          const new_pages = response.data;
          if (new_pages.length > 0) {
            let pagesData = [];
            for (let i = 0; i < new_pages.length; i++) {
              pagesData = [
                ...pagesData,
                {
                  connected_id: new_pages[i].id,
                  name: new_pages[i].name,
                  access_token: new_pages[i].access_token,
                  social_id: fbId ? fbId : socialId,
                  status: "off",
                },
              ];
            }
            setPages(pagesData);
          }
        }
      }
    );
  };

  const handleClick = (el) => {
    try {
      let filter = pages.filter(
        (page) => page.connected_id !== el.connected_id
      );
      filter = [
        ...filter,
        { ...el, status: el.status === "on" ? "off" : "on" },
      ];
      setPages(filter);
    } catch (error) {
      console.log(error);
    }
  };

  const connectPages = async () => {
    try {
      const body = { data: pages };
      const res = await linkSocialApi(body);
      if (res.data && res.data.status) {
        toast.success("pages connected");
        setCurrentTab('home');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <button onClick={handleLogin}>Connect With Fb</button>
      <hr />
      {pages && pages.length > 0 && (
        <div>
          <h3>Faceboook Pages</h3>
          {pages.map((el) => (
            <div>
              <h4>{el.name}</h4>
              <button onClick={() => handleClick(el)}>
                {el.status === "on" ? "disconnect" : "connect"}
              </button>
            </div>
          ))}
          <button onClick={connectPages}>Connect Pages</button>
        </div>
      )}
    </div>
  );
};

export default FacebookConnection;
