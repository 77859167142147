import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getSocialConnection } from "../../../../Services/api";

const SideBar = ({ currentTab, setCurrentTab, connections }) => {
  return (
    <div
      className="col-1 logo-section d-flex flex-column align-items-center"
      style={{ maxWidth: "73px" }}
    >
      <ul
        className="nav flex-column text-center mt-3"
        id="myTab"
        role="tablist"
      >
        <li className={`nav-item mb-3 active`} role="presentation">
          <button
            className="nav-link p-0"
            id="store-tab"
            data-bs-toggle="tab"
            data-bs-target="#store"
            type="button"
            role="tab"
            aria-controls="store"
            aria-selected="false"
            tabindex="-1"
            onClick={() => setCurrentTab("home")}
          >
            <Link>
              <img
                src="../assets/images/social-store1.svg"
                alt="Logo"
                className="img-fluid"
              />
            </Link>
          </button>
        </li>

        <li className={`nav-item mb-3 active`} role="presentation">
          <button
            className="nav-link active p-0"
            id="home-tab"
            data-bs-toggle="tab"
            data-bs-target="#home"
            type="button"
            role="tab"
            aria-controls="home"
            aria-selected="true"
            onClick={() => setCurrentTab("facebook")}
          >
            {connections.includes("facebook") && (
              <div className="selected">
                <i className="bi bi-check-lg"></i>
              </div>
            )}
            <Link>
              <img
                src="../assets/images/social-fb.png"
                alt="Facebook"
                className="img-fluid"
              />
            </Link>
          </button>
        </li>
        <li
          className={`nav-item mb-3 ${currentTab === "instagram" && "active"}`}
          role="presentation"
        >
          <button
            className="nav-link p-0"
            id="profile-tab"
            data-bs-toggle="tab"
            data-bs-target="#profile"
            type="button"
            role="tab"
            aria-controls="profile"
            aria-selected="false"
            tabindex="-1"
            onClick={() => setCurrentTab("instagram")}
          >
            {connections.includes("instagram") && (
              <div className="selected">
                <i className="bi bi-check-lg"></i>
              </div>
            )}
            <Link>
              <img
                src="../assets/images/social-insta.png"
                alt="Instagram"
                className="img-fluid"
              />
            </Link>
          </button>
        </li>
        <li className={`nav-item mb-3 active`} role="presentation">
          <button
            className="nav-link p-0"
            id="wtsapp-tab"
            data-bs-toggle="tab"
            data-bs-target="#wtsapp"
            type="button"
            role="tab"
            aria-controls="wtsapp"
            aria-selected="false"
            tabindex="-1"
            onClick={() => setCurrentTab("whatsapp")}
          >
            {connections.includes("whatsApp") && (
              <div className="selected">
                <i className="bi bi-check-lg"></i>
              </div>
            )}
            <Link>
              <img
                src="../assets/images/social-wtsapp.png"
                alt="WhatsApp"
                className="img-fluid"
              />
            </Link>
          </button>
        </li>
        <li className={`nav-item mb-3 active`} role="presentation">
          <button
            className="nav-link p-0"
            id="contact-tab"
            data-bs-toggle="tab"
            data-bs-target="#contact"
            type="button"
            role="tab"
            aria-controls="contact"
            aria-selected="false"
            tabindex="-1"
            onClick={() => setCurrentTab("widget")}
          >
            {connections.includes("chat_widget") && (
              <div className="selected">
                <i className="bi bi-check-lg"></i>
              </div>
            )}
            <Link href="#">
              <img
                src="../assets/images/social-msg.png"
                alt="Messenger"
                height="43px"
                className="img-fluid"
              />
            </Link>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
