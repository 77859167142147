import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getConnectionList } from "../Services/api";


export const getConnection = createAsyncThunk(
  "otpvrification",
  async (_, { rejectWithValue }) => {
    try {
      console.log('under hit');
      const response = await getConnectionList();
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const whatsAppSlice = createSlice({
  name: "whatsApp",
  initialState: {
    submitting: false,
    error: null,
    connections:[],
    loading:false,
    success: false,
  },
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getConnection.pending, (state) => {
        state.submitting = true;
        state.error = null;
        state.success = false;
        state.loading = true;
    
      })
      .addCase(getConnection.fulfilled, (state, action) => {
        state.submitting = false;
        state.connections = action.payload.data;
        state.loading = false;
        state.success = true;
      })
      .addCase(getConnection.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload.data.message;
        state.success = false;
        state.loading = false;
      })
  },
});

export default whatsAppSlice.reducer;
