import React, { useContext, useEffect, useRef, useState } from "react";
import { SocketContext } from "../../../../Context";
import { searchChatUsers } from "../../../../Services/api";
import { useSelector } from "react-redux";
import ChatUser from "../../Static/ChatUser";

const ChatList = ({ setPage }) => {
  const { chatUser, setCurrent,currentUser, socket, setChatUser, ChatfirstEmits } =
    useContext(SocketContext);
  const selector = useSelector((state) => state.auth);
  const [searchData, setSearchData] = useState("");
  const [userPage, setUserPage] = useState(1);
  const usersRef = useRef();
  console.log("<----------chatUser-------->", chatUser);
  // useEffect(() => {
  //   // searchHandle(searchData);
  // }, [userPage]);

  useEffect(() => {
    if (chatUser.length > 0 && Object.keys(currentUser).length==0) {
      setCurrent(chatUser[0]);
    }
  }, [chatUser]);

  useEffect(() => {
    if (socket) {
      ChatfirstEmits();
    }
  }, [socket, selector.token]);

  // const searchHandle = (value) => {
  //   const body = {
  //     search: value,
  //     id: selector.auth.uid,
  //     page: userPage,
  //     pageSize: 15,
  //   };
  //   searchUserFun(body);
  // };

  // const searchUserFun = async (body) => {
  //   try {
  //     const { data } = await searchChatUsers(body);
  //     if (userPage > 1) {
  //       setChatUser([...chatUser, ...data.data]);
  //     } else {
  //       setChatUser(data.data);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const searchUser = async (value) => {
    const body = {
      search: value,
      id: selector.auth.uid,
      page: 1,
      pageSize: 15,
    };
    try {
      const { data } = await searchChatUsers(body);
      setChatUser(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const setChatModal = (user) => {
    setCurrent(user);
    setPage(1);
    const data = { selectId: user.connection_user };
    socket.emit("fetch_chat_by_users", data);
  };

  const handleScroll = () => {
    if (usersRef.current) {
      let { scrollTop, scrollHeight, clientHeight, offsetTop } =
        usersRef.current;
      if (clientHeight + scrollTop === scrollHeight) {
        setUserPage(userPage + 1);
      }
    }
  };

  return (
    <div
      class="col-2 user-section bg-white p-0"
      onScroll={handleScroll}
      ref={usersRef}
    >
      <div class="search-bar p-3">
        {/* <!-- <i class="bi bi-search"></i> --> */}
        <input
          type="text"
          className="form-control"
          placeholder="Search users"
          value={searchData}
          onChange={(e) => {
            setUserPage(1);
            setSearchData(e.target.value);
            searchUser(e.target.value);
          }}
        />
      </div>
      <h3>
        Chats{" "}
        <select style={{ float: "right" }}>
          <option selected="">All</option>
          <option>Unread</option>
          <option>Pin</option>
          <option>Blocked</option>
        </select>
      </h3>
      <div class="chat-list p-3">
        <ul class="list-group">
          {chatUser.length > 0 ? (
            chatUser.map((x, i) => {
              return (
                <ChatUser
                  data={x}
                  click={() => {
                    setChatModal(x);
                  }}
                  index={i}
                />
              );
            })
          ) : (
            <div class="search_image text-center mt-4">
              <img class="search_image" src="/assets/images/image_search.png" />
              <h3>No Data Found</h3>
            </div>
          )}
        </ul>
      </div>
    </div>
  );
};

export default ChatList;
