import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import "./head.css";

const Header = () => {
  const { userDetails } = useSelector((state) => state.auth);

  // Dynamic menu with subMenu as an array
  const headerMenu = [
    {
      title: "Home",
      path: "/",
    },
    {
      title: "Leads",
      path: "/leads",
      subMenu: [
        { name: "Action", path: "/action" },
        { name: "Another Action", path: "/another-action" },
      ],
    },
    {
      title: "Automation",
      path: "/automation",
      subMenu: [],
    },
    {
      title: "Messages",
      path: "/new-chat",
    },
    {
      title: "Settings",
      path: "/settings",
      subMenu: [
        { name: "Profile", path: "/profile" },
        { name: "Account", path: "/account" },
      ],
    },
  ];

  const handleUserDropdown = (event) => {
    const dropdownMenu = event.currentTarget.querySelector(".dropdown-menu");
    if (dropdownMenu) {
      dropdownMenu.classList.toggle("show");
    }
  };

  const handleLogout = () => {
    localStorage.clear();
  };
  return (
    <header>
      <div className="container">
        <nav className="navbar navbar-expand-lg">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/">
              LOGO
            </Link>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mid-ul ms-auto mb-2 mb-lg-0">
                {/* Dynamically generate menu items */}
                {headerMenu.map((menu, index) => (
                  <li
                    key={index}
                    className={`nav-item ${menu.subMenu ? "dropdown" : ""}`}
                    onClick={menu.subMenu ? handleUserDropdown : undefined}
                  >
                    <Link
                      className={`nav-link ${
                        menu.subMenu ? "dropdown-toggle" : ""
                      }`}
                      to={menu.subMenu ? "#" : menu.path}
                      role={menu.subMenu ? "button" : ""}
                      data-bs-toggle={menu.subMenu ? "dropdown" : ""}
                      aria-expanded="false"
                    >
                      {menu.title}
                    </Link>
                    {/* Dynamically generate sub-menu if available */}
                    {menu.subMenu && menu.subMenu.length > 0 && (
                      <ul className="dropdown-menu">
                        {menu.subMenu.map((sub, subIndex) => (
                          <li key={subIndex}>
                            <Link className="dropdown-item" to={sub.path}>
                              {sub.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}
              </ul>

              <ul className="navbar-nav me-2 mb-2 mb-lg-0 d-flex justify-content-center align-items-center">
                <li className="nav-item">
                  <a className="nav-link" href="#" aria-disabled="true">
                    Usage and Plan <i className="bi bi-arrow-repeat"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" aria-disabled="true">
                    <i className="bi bi-question-circle"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#" aria-disabled="true">
                    <i className="bi bi-bell"></i>
                  </a>
                </li>

                {/* User Profile Dropdown */}
                <li
                  className="nav-item dropdown dropdown-right"
                  onClick={handleUserDropdown}
                >
                  <img src="../assets/images/social-user.png" alt="User" />
                  <Link
                    className="nav-link dropdown-toggle"
                    to="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userDetails?.name || "User"}
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/profile">
                        Profile
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/settings">
                        Settings
                      </Link>
                    </li>
                    <li>
                      <hr className="dropdown-divider" />
                    </li>
                    <li>
                      <Link
                        className="dropdown-item"
                        to={"/"}
                        onClick={handleLogout}
                      >
                        Logout
                      </Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;
