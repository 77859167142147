import { hexToHsva, hsvaToHex } from "@uiw/color-convert";
import ShadeSlider from "@uiw/react-color-shade-slider";
import Wheel from "@uiw/react-color-wheel";
import React, { useState } from "react";

const iconTypes = [
  { id: 1, value: "icon", name: "Round" },
  { id: 2, value: "text", name: "Square" },
];

const icons = [
  {
    className: "bi bi-chat-fill",
    active: true,
  },
  {
    className: "bi bi-chat-text-fill",
    active: false,
  },
  {
    className: "fa-solid fa-comments",
    active: false,
  },
];

const Customization = ({
  nextStep,
  headerColor,
  setHeaderColor,
  iconType,
  setIconType,
  iconPlacement,
  setIconPlacement,
  selectedIcon,
  setSelectedIcon,
}) => {
  const [changeColor, setChangeColor] = useState(false);
  const [chatIcons, setChatIcons] = useState(icons);

  const handleColor = (color) => {
    if (changeColor) {
      setHeaderColor(color.hex);
      changeCard("chat_header", "chat_btn");
    }
  };

  const handleShade = (newShade) => {
    if (changeColor) {
      const hsva = hexToHsva(headerColor);
      const newHeaderColor = { ...hsva, ...newShade };
      setHeaderColor(hsvaToHex(newHeaderColor));
    }
  };

  const changeCard = (removeCardId, addCardId) => {
    const addCard = document.getElementById(removeCardId);
    const removeCard = document.getElementById(addCardId);
    removeCard.classList.add("d-none");
    addCard.classList.remove("d-none");
  };

  const handleIconType = (e) => {
    setIconType(e.target.value);
    changeCard("chat_btn", "chat_header");
  };

  const handleIconPosition = (e) => {
    setIconPlacement(e.target.value);
    changeCard("chat_btn", "chat_header");
  };

  const handleSelectedIcon = (className) => {
    const updatedIcons = chatIcons.map((icon) => ({
      ...icon,
      active: icon.className === className, // Set the selected icon as active, others as inactive
    }));

    setChatIcons(updatedIcons);
    setSelectedIcon(className);
    changeCard("chat_btn", "chat_header");
  };
  return (
    <div className="row mt-5">
      <div className="col-md-6 chat2-right">
        <div className="section-title mb-4">
          <h4>How the chat widget will look?</h4>
        </div>
        <div className="mb-3">
          <h6>Color palette</h6>
          <div className="color-picker">
            <Wheel color={headerColor} onChange={handleColor} />
            <ShadeSlider hsva={hexToHsva(headerColor)} onChange={handleShade} />
            <p>
              <span
                className="clr-border"
                style={{
                  background: headerColor,
                }}
              ></span>
              {headerColor}
            </p>
          </div>
        </div>
        <div className="form-check form-switch">
          <input
            className="form-check-input"
            type="checkbox"
            id="flexSwitchCheckChecked"
            checked={changeColor}
            onChange={() => setChangeColor(!changeColor)}
          />
          <label className="form-check-label mb-3" for="flexSwitchCheckChecked">
            Color the Chat Header
          </label>
        </div>
        <div className="mb-3">
          <h6>Chat button style</h6>
          <div className="d-flex flex-column align-items-start justify-content-center chat-opt">
            <div className="me-3">
              <select className="form-select" onChange={handleIconType}>
                {iconTypes &&
                  iconTypes.map((type) => (
                    <option key={type.id} value={type.value}>
                      {type.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="d-flex align-items-center chat-btn-select">
              {chatIcons &&
                chatIcons.map((icon) => (
                  <button
                    className={`btn btn-chat-icon me-2 ${
                      icon.active ? "" : "btn-outline-secondary rounded-circle"
                    }`}
                    onClick={() => {
                      handleSelectedIcon(icon.className);
                    }}
                  >
                    {/* <img src={icon.url} height="24px" /> */}
                    <i
                      className={icon.className}
                      style={{ color: headerColor, fontSize: "24px" }}
                    ></i>
                  </button>
                ))}
            </div>
          </div>
        </div>
        <div className="mb-3">
          <h6>Chat button position</h6>
          <select
            className="form-select"
            id="chatButtonPosition"
            onChange={handleIconPosition}
          >
            <option value={"left"}>
              At the bottom of the screen, on the left
            </option>
            <option value={"right"}>At the bottom-right corner</option>
          </select>
        </div>
        <div className="form-text">
          To increase the number of chats, we recommend to place the widget in
          the bottom-right corner.
        </div>
      </div>

      {/* Header Card */}
      <div className="col-md-6" id="chat_header">
        <div className="chat-widget-preview border rounded">
          <div
            className="chat-header text-white d-flex justify-content-between align-items-center p-2"
            style={{ background: headerColor }}
          >
            <span style={{ fontSize: "14px", fontWeight: "300" }}>
              <span className="online"></span> Chat with us, we are online!
            </span>
            <button className="btn p-0 border-0" aria-label="Close chat">
              <i className="bi bi-x-lg text-white"></i>
            </button>
          </div>
          <div className="chat-body p-4 d-flex flex-column align-items-center">
            <div className="avatar-circle bg-light text-dark rounded-circle d-flex align-items-center justify-content-center mb-2">
              <span>AB</span>
            </div>
            <span>Andrew Bine</span>
          </div>
          <div className="chat-footer p-2">
            <input
              type="text"
              className="form-control"
              placeholder="Message..."
            />
          </div>
        </div>
      </div>

      {/* ChatIcon Card */}
      <div className={`col-md-6 d-none`} id="chat_btn">
        <div className="chat-card">
          {iconType === "icon" ? (
            <i
              className={`${selectedIcon} ${
                iconPlacement === "left" ? "i-left" : "i-right"
              }`}
              style={{ color: headerColor, fontSize: "37px" }}
            ></i>
          ) : (
            <p
              className={iconPlacement === "left" ? "p-left" : "p-right"}
              style={{ background: headerColor }}
            >
              <span class="online me-2"></span>Chat With us
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default Customization;
