import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import ConfirmationBox from "../../ConfirmationBox";
import { SocketContext } from "../../../Context";
let limit = 0;
let warn = 0;
let acpt = false;

const UserInterface = ({ style }) => {
  const [callDurations, setCallDurations] = useState([]);
  const [warning, setWarning] = useState(false);
  const {
    callTimerInterval,
    video,
    startTime,
    ans,
    answerCall,
    callAccepted,
    stream,
    call,
    handleMute,
    userView,
    leaveCall,
    mute,
    setMute
  } = useContext(SocketContext);
  const currentUser = useSelector((state) => state.auth?.currentUser);
  const { funds, call_price, call_warn } = useSelector((state) => state.auth);
  const userRef = useRef(null);
  useEffect(() => {
    if (userRef.current && stream && userView) {
      userRef.current.srcObject = userView;
    }
  }, [userView]);

  useEffect(() => {
    validations();
  }, [funds]);

  useEffect(() => {
    if (
      ans &&
      call.isReceivingCall &&
      !callAccepted &&
      stream &&
      stream !== null
      && !acpt
    ) {
      answerCall();
      acpt=true;
      setTimeout(() => {
        acpt=false;
      }, 2000);
    }
    if (callAccepted) {
      startCall();
    }
  }, [callAccepted]);

  const startCall = () => {
    callTimerInterval.current = setInterval(updateCallTimer, 1000);
  };

  const validations = async () => {
    if (funds && call_price && call_warn) {
      const sec = (funds / call_price) * 60;
      const warningSecond = call_warn * 60;
      const warning = sec - warningSecond;
      limit = Math.round(sec);
      warn = Math.round(warning);
      let pending = limit - callDurations.slice(0, -1);
      if (pending > warn) {
        setWarning(false);
      }
    }
  };

  const muteFun = () => {
    stream.getAudioTracks().forEach(async function (track) {
      if (!mute) {
        track.enabled = true;
        setMute(true);
        handleMute(video, true);
      } else {
        track.enabled = false;
        setMute(false);
        handleMute(video, false);
      }
    });
  };


  function endCall(type) {
    clearInterval(callTimerInterval.current);
    if (startTime !== null) {
      const endTime = new Date().getTime();
      const durationInSeconds = Math.floor((endTime - startTime) / 1000);
      setCallDurations([...callDurations.slice(0, -1), durationInSeconds]);
      leaveCall(durationInSeconds, type);
    } else {
      leaveCall("4", type);
    }
  }

  const updateCallTimer = async () => {
    const currentTime = new Date().getTime();
    const st = startTime !== null ? startTime : currentTime;
    const elapsedTimeInSeconds = Math.floor((currentTime - st) / 1000);

    setCallDurations([...callDurations.slice(0, -1), elapsedTimeInSeconds]);
  };

  const formatDuration = (seconds) => {
    if (seconds) {
      const hours = Math.floor(seconds / 3600);
      const minutes = Math.floor((seconds % 3600) / 60);
      const remainingSeconds = seconds % 60;
      if (hours > 0) {
        return `${hours} hrs ${minutes} min ${remainingSeconds}sec`;
      } else if (minutes > 0) {
        return `${minutes} min ${remainingSeconds}sec`;
      } else {
        return `${remainingSeconds} sec`;
      }
    }
  };

  const currentCallDuration = useMemo(
    () =>
      startTime !== null &&
      callDurations[0] > 0 &&
      formatDuration(callDurations.slice(-1)[0]),
    [startTime, callDurations]
  );

  const confirm = () => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return <ConfirmationBox cutt={() => endCall("4")} onClose={onClose} />;
      },
    });
  };
  
  return (
    <div className={style}>
    <div class="calling d-flex">
      <video
        playsInline
        ref={userRef}
        autoPlay
        className="d-none"
      />

    <div class="icon">
      <a onClick={() => muteFun()} data-tooltip-id={"mice"}>
        <i
          className={
            mute == true ? "bi bi-mic" : "bi bi-mic-mute-fill"
          }
        ></i>
      </a>
      <a className="telephone" onClick={() => confirm()} data-tooltip-id={"end"}>
        <i className="bi bi-telephone-fill"></i>
      </a>
    </div>
    <div>
      <p>{currentCallDuration}</p>
    </div>
  </div>
      <Tooltip id="end" content="End call" style={{ zIndex: 999 }} />
      <Tooltip
        id="mice"
        content={mute ? "Mute" : "Unmute"}
        style={{ zIndex: 999 }}
      />
    </div>
  );
};

export default UserInterface;
