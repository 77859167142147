import React from "react";
import ReactTimeAgo from "react-time-ago";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Example from "../Example";
import ChatImageAndVideoPreviewModel from "../ChatImageAndVideoPreviewModel";
import { downloadMedia } from "../../Helpers/help";
import { mediaUrl } from "../../Network/path";

const MessageBox = ({ data, style, right, typing }) => {
  const [imageVideoShow, setImageVideoShow] = React.useState(false);
  const [dataa, setDataa] = React.useState({});
  const selector = useSelector((state) => state.auth);

  const handleImageVideo = (type, imageVideo) => {
    setDataa({ type, data: imageVideo });
    setImageVideoShow(!imageVideoShow);
  };
  const dt = localStorage.getItem("dt");
  const messageCondition = data.file_type !== "" || data.message !== "";

  return (
    <div className={style}>
      <div
        className={`msg ${
          data.duration === 0
            ? right === false
              ? "misscall"
              : "misscallmy"
            : ""
        }`}
      >
        {!data.offer && !data.title && (
          <div
            className={`${
              right === false
                ? `txt txt2 ${data.file_type === "application" ? "pdf" : ""}`
                : `txt ${
                    data.file_type === "application" ? "pdf" : "misscallmy"
                  }`
            } w-100`}
            id={data.id}
          >
            {messageCondition && data.duration !== 0 && (
              <h4 className="mb-0">
                {data.file_type === "application" && (
                  <Link
                    className="pdf"
                    href="#"
                    onClick={() => downloadMedia(mediaUrl + data.file)}
                  >
                    <i className="bi bi-file-earmark-pdf-fill"></i>
                    <h6>{data.file}</h6>
                  </Link>
                )}

                {data.file_type === "image" && (
                  <img
                    onClick={() =>
                      handleImageVideo("image", mediaUrl + data.file)
                    }
                    src={mediaUrl + data.file}
                    loading="lazy"
                    alt="user"
                  />
                )}

                {data.file_type === "video" && (
                  <video
                    onClick={() =>
                      handleImageVideo("video", mediaUrl + data.file)
                    }
                    width="320"
                    height="240"
                    controls
                  >
                    <source src={mediaUrl + data.file} type="video/mp4" />
                  </video>
                )}
                {data.file_type === "mp3" &&
                  data.type === "call" &&
                  data.duration > 0 && (
                    <audio controls loop={false}>
                      <source
                        src={mediaUrl + data.recording}
                        type="audio/mpeg"
                      />
                    </audio>
                  )}
                {data.duration !== 0 && data.type !== "default" && data.message}
                {data.type === "default" &&
                  JSON.parse(data.message).length > 0 &&
                  JSON.parse(data.message).map((el) => (
                    <>
                      <div className="main_ques">
                        <h6>Ques.{el.ques}</h6>
                        <p>Ans.{el.ans}</p>
                      </div>
                    </>
                  ))}
              </h4>
            )}
            {data.duration === 0 && (
              <h6 className="center">
                <i className="bi bi-telephone-inbound-fill"></i>
                {`CALL ${
                  data.sender_id === selector.auth.uid
                    ? "NOT ANSWERED"
                    : "MISSED"
                }`}
              </h6>
            )}
          </div>
        )}

        <h6 className="pe-0">
          <ReactTimeAgo
            date={data.unix_time * 1000}
            locale="en-US"
            timeStyle="twitter-minute-now"
          />
          {right && data.sms_status === "sent" && (
            <i className="bi bi-check ms-2 text-secondary"></i>
          )}
          {right && data.sms_status === "read" && (
            <i className="bi bi-check-all ms-2"></i>
          )}
          {right && data.sms_status === "delivered" && (
            <i className="bi bi-check-all ms-2 text-secondary"></i>
          )}
        </h6>
      </div>
      <Example
        show={imageVideoShow}
        onHide={() => setImageVideoShow(!imageVideoShow)}
        component={
          <ChatImageAndVideoPreviewModel
            onHide={() => setImageVideoShow(!imageVideoShow)}
            data={dataa.data}
            type={dataa.type}
          />
        }
        style={"pop_up showimage"}
      />
    </div>
  );
};
export default MessageBox;
