import React, { useState } from "react";
import Button from "../../Components/UI/button";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { secondUrl } from "../../Network/path";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setToken, setUserDetails } from "../../Reducer/authSlice";

const SocialRegister = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoogleLogin = useGoogleLogin({
    onSuccess: (response) => {
      getGoogleUserDetails(response);
    },
    onError: (error) => console.log(error),
    scope: "profile email",
    access_type: "offline", // Request offline access for refresh token
    prompt: "consent",
    flow: "auth-code",
  });

  const getGoogleUserDetails = async (googleRes) => {
    try {
      const res = await axios.post(
        `${secondUrl}v6/googleAuth`,
        {
          code: googleRes.code,
        },
        {
          headers: {
            Accept: "application/json",
          },
        }
      );
      if (res.data && res.data.status === "success") {
        toast.success("Logged In", {
          position: "bottom-center",
        });
        localStorage.setItem("tokenk", res.data.token);
        dispatch(setToken(res.data.token));
        dispatch(setUserDetails(res.data.data));
        navigate("/");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong", {
        position: "bottom-center",
      });
    }
  };

  const getFbUserDetails = async (response) => {
    try {
      const res = await axios.post(`${secondUrl}v5/fbAuth`, {
        userId: response.userID,
        accessToken: response.accessToken,
      });
      if (res.data && res.data.status === "success") {
        toast.success("Logged In", {
          position: "bottom-center",
        });
        localStorage.setItem("tokenk", res.data.token);
        dispatch(setToken(res.data.token));
        dispatch(setUserDetails(res.data.data));
        navigate("/");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong", {
        position: "bottom-center",
      });
    }
  };

  const handleFacebookLogin = async () => {
    try {
      window.FB.login(
        function (response) {
          if (response.authResponse) {
            getFbUserDetails(response.authResponse);
          }
        },
        {
          scope: "email, pages_show_list,public_profile",
        }
      );
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong", {
        position: "bottom-center",
      });
    }
  };
  
  return (
    <div className="d-flex gap-3 mobile-view">
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
        <Button
          title={"Sign In"}
          type={"button"}
          className={"sign-in_ggl"}
          onClick={handleGoogleLogin}
        >
          {" "}
          <svg className="icon" viewBox="0 0 24 24" height="21px" width="22px">
            <path
              d="M22.56 12.25c0-.78-.07-1.53-.2-2.25H12v4.26h5.92c-.26 1.37-1.04 2.53-2.21 3.31v2.77h3.57c2.08-1.92 3.28-4.74 3.28-8.09z"
              fill="#4285F4"
            ></path>
            <path
              d="M12 23c2.97 0 5.46-.98 7.28-2.66l-3.57-2.77c-.98.66-2.23 1.06-3.71 1.06-2.86 0-5.29-1.93-6.16-4.53H2.18v2.84C3.99 20.53 7.7 23 12 23z"
              fill="#34A853"
            ></path>
            <path
              d="M5.84 14.09c-.22-.66-.35-1.36-.35-2.09s.13-1.43.35-2.09V7.07H2.18C1.43 8.55 1 10.22 1 12s.43 3.45 1.18 4.93l2.85-2.22.81-.62z"
              fill="#FBBC05"
            ></path>
            <path
              d="M12 5.38c1.62 0 3.06.56 4.21 1.64l3.15-3.15C17.45 2.09 14.97 1 12 1 7.7 1 3.99 3.47 2.18 7.07l3.66 2.84c.87-2.6 3.3-4.53 6.16-4.53z"
              fill="#EA4335"
            ></path>
            <path d="M1 1h22v22H1z" fill="none"></path>
          </svg>
          <span className="font-size">Continue with Google</span>
        </Button>
      </div>
      <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-2">
        <Button
          title={"Sign In"}
          type={"button"}
          className={"sign-in_apl sign-in_apl-top"}
          onClick={handleFacebookLogin}
        >
          <svg
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            height="21px"
            width="22px"
            fill="#1877F2"
            color="#fff"
          >
            <path d="M12.001 2C6.47813 2 2.00098 6.47715 2.00098 12C2.00098 16.9913 5.65783 21.1283 10.4385 21.8785V14.8906H7.89941V12H10.4385V9.79688C10.4385 7.29063 11.9314 5.90625 14.2156 5.90625C15.3097 5.90625 16.4541 6.10156 16.4541 6.10156V8.5625H15.1931C13.9509 8.5625 13.5635 9.33334 13.5635 10.1242V12H16.3369L15.8936 14.8906H13.5635V21.8785C18.3441 21.1283 22.001 16.9913 22.001 12C22.001 6.47715 17.5238 2 12.001 2Z"></path>
          </svg>
          <span className="font-size">Continue with Facebook</span>
        </Button>
      </div>
    </div>
  );
};

export default SocialRegister;
